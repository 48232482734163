import React, { Component } from "react";
import "./dashboard.css";
import Event from "../event/event";
import LoadingBar from "../loading/loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Dashboard extends Component {
  componentDidMount() {
    this.props.fetchEvent();
  }
  render() {
    const { loading, error, events } = this.props;
    return (
      <div>
        <div className="container mb-5">
          <div className="headText text-center">
            <h1 className="headTitle">Evenimente</h1>
            <h6 className="headDescription">
              Descoperă cele mai recente curse de Mountain Bike si Running din
              Suceava.
            </h6>
          </div>
          <div className="col-md-12 events">
            {loading ? (
              <div className="row eventRow">
                <LoadingBar />
              </div>
            ) : error ? (
              <h1 className="headTitle">Error</h1>
            ) : (
              <div>
                <div className="row dashboard-event">
                  {events.map((event, index) => (
                    <div
                      key={index}
                      className={
                        events.length === 1
                          ? "col-md-12"
                          : events.length === 2
                          ? "col-md-6"
                          : "col-md-4"
                      }
                    >
                      <Event event={event} length={events.length} />
                    </div>
                  ))}
                </div>
                <a
                  href="https://www.youtube.com/watch?v=2De9Pd4gPw4"
                  target="_blank"
                >
                  <button type="button" className="btn-youtube">
                    <i className="fa fa-youtube-play"></i>
                    <span className="youtube-span">Tutorial Aplicatie</span>
                  </button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
